<script setup lang="ts">
import { useUserStore } from '@voix/store/userStore'
// TODO: Decide on a single animation library motion one or animejs
import anime from 'animejs'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'

const open = ref(false)
const showDisclaimer = ref(false)
const showLinks = ref(true)
const chatModeAi = ref(true)

const baseHeight = 600
const chatboxHeight = ref(baseHeight)
const newMessage = ref('')
const isSendingMessage = ref(false)

const messages: Ref<{ id: number | string, text: string, type: 'bot' | 'user' }[]> = ref([
  {
    id: 0,
    text: 'Hello, how can I help you?',
    type: 'bot',
  },
])

const links = ref([])

const feedbackType: Ref<string | null> = ref(null)
const feedback: Ref<string> = ref('')
const feedbackId: Ref<string | null> = ref(null)
const feedbackComplete: Ref<string[]> = ref([])

const threadId = ref(null)

const isOpenHours = computed(() => {
  const d = new Date()
  const hour = d.getUTCHours()

  return hour > 14 && hour < 21
})

const canSendMessage = computed(() => {
  // Get the last message
  const lastMessage = messages.value[messages.value.length - 1]

  // If the last message is from the bot
  if (lastMessage.type === 'bot' && newMessage.value.length > 3)
    return true

  return false
})

watch(chatModeAi, (newValue) => {
  if (newValue === false) {
    showFiveNine()

    $fetch(`https://atl-search.lbm.co/api/chat/${feedbackId.value}/feedback/live-agent`, {
      method: 'post',
      body: { comment: feedback.value },
    })
  }
})

onMounted(() => {
  chatboxHeight.value = calculateChatboxHeight()

  if (typeof window !== 'undefined') {
    window.addEventListener('resize', () => {
      chatboxHeight.value = calculateChatboxHeight()
    })
  }

  anime({
    targets: '#helpbot-thinker div',
    keyframes: [
      { translateY: -5, background: '#fff', height: '12' },
      { translateY: 5, background: '#aaa', height: '5' },
      { translateY: 0, background: '#ccc', height: '5' },
    ],
    duration: 700,
    delay: anime.stagger(100),
    easing: 'easeInOutQuad',
    loop: true,
  })

  anime({
    targets: '#helpbot-thinker-post div',
    keyframes: [
      { translateX: 5, background: '#c53030' },
      { translateX: 0, background: '#fff' },
    ],
    duration: 700,
    delay: anime.stagger(100),
    easing: 'easeInOutQuad',
    loop: true,
  })

  const disclaimerCookie = useCookie('helpbot_disclaimer')
  if (disclaimerCookie.value !== 'accepted')
    showDisclaimer.value = true

  else
    showDisclaimer.value = false

  setTimeout(() => {
    hideFiveNine()
  }, 1000)
})

declare global {
  interface Window {
    Five9SocialWidget: any
  }
}

function hideFiveNine() {
  if (typeof window === 'undefined')
    return

  const five9 = window.Five9SocialWidget
  if (five9)
    five9.removeWidget()
}

function showFiveNine() {
  const five9 = window.Five9SocialWidget
  if (five9) {
    const options = {
      rootUrl: 'https://app.five9.com/consoles/',
      type: 'chat',
      title: 'Atlantis Live Chat',
      tenant: 'atlantisparadise.com.',
      profiles: ['Select', 'Chat - Planning Trip', 'Chat - On-Property'],
      profileLabel: 'For guests on property, we are available to chat from 10am-10pm ET.',
      showProfiles: true,
      autostart: true,
      theme: 'https://www.atlantisbahamas.com/five-nine/chat-theme.css',
      surveyOptions: {
        showComment: false,
        requireComment: false,
      },
      fields: {
        'name': {
          value: '',
          show: true,
          label: 'Name*',
        },
        'email': {
          value: '',
          show: true,
          label: 'Email*',
        },
        'Contact.number1': {
          value: '',
          show: true,
          label: 'Phone',
        },
        'question': {
          value: '',
          show: true,
          label: 'How may we help you?*',
        },
      },
      playSoundOnMessage: true,
      allowCustomerToControlSoundPlay: false,
      showEmailButton: false,
      hideDuringAfterHours: true,
      useBusinessHours: true,
      showPrintButton: true,
      allowUsabilityMenu: false,
      enableCallback: false,
      callbackList: '',
      allowRequestLiveAgent: false,
    }
    five9.addWidget(options)

    setTimeout(() => {
      five9.maximizeChat(options)
    }, 1000)
  }
}

function acceptDisclaimer() {
  const disclaimerCookie = useCookie('helpbot_disclaimer', { expires: dayjs().add(1, 'year').toDate() })
  disclaimerCookie.value = 'accepted'
  showDisclaimer.value = false
}

function sendPremadeMessage(premadeMessage: string) {
  if (!newMessage.value) {
    newMessage.value = premadeMessage
    sendMessage()
  }
}

const userStore = useUserStore()
const currentUser = userStore.currentUser

function sendMessage() {
  if (canSendMessage.value) {
    isSendingMessage.value = true
    const loggedInUser = currentUser ? 1 : 0
    messages.value.push({
      id: uuidv4(),
      text: newMessage.value,
      type: 'user',
    })

    const message = newMessage.value
    newMessage.value = ''
    links.value = []

    fetch(`https://atl-search.lbm.co/api/assistant/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        thread_id: threadId.value,
        message,
        user: loggedInUser,
      }),
    })
      .then(async (response) => {
        if (response.ok === true) {
          await response.json().then((jsonResponse) => {
            threadId.value = jsonResponse.thread_id

            messages.value.push({
              id: jsonResponse.thread_id,
              text: jsonResponse.response,
              type: 'bot',
            })

            getRelatedPages(jsonResponse)

            scrollChatToBottom()
          })
          newMessage.value = ''
        }
      })
      .finally(() => {
        isSendingMessage.value = false
      })
    scrollChatToBottom()
  }
}

function getRelatedPages(chatResponse) {
  const fullMessage = `${newMessage.value} ${chatResponse.response}`
  fetch(`https://atl-search.lbm.co/api/assistant/search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify({
      thread_id: chatResponse.thread_id,
      message: chatResponse.response,
    }),
  })
    .then(async (response) => {
      if (response.ok === true) {
        await response.json().then((jsonResponse) => {
          links.value = jsonResponse

          scrollChatToBottom()
        })
        newMessage.value = ''
      }
    })
    .finally(() => {
      isSendingMessage.value = false
    })
}

function scrollChatToBottom() {
  setTimeout(() => {
    const chatbox = document.querySelector('#chatbox')
    if (chatbox)
      chatbox.scrollTop = chatbox.scrollHeight
  }, 100)
}

function calculateChatboxHeight() {
  if (typeof window !== 'undefined') {
    let minHeight = baseHeight
    const maxHeight = baseHeight

    if (!isOpenHours.value)
      minHeight += 60

    const windowHeight = window.innerHeight * 0.40

    // If window is less than minHeight
    if (windowHeight < minHeight)
      return minHeight - 80 - 115

    // If window is greater than maxHeight
    if (windowHeight > maxHeight)
      return maxHeight - 80 - 115

    // If window is between minHeight and maxHeight
    return (window.innerHeight * 0.40) - 80 - 115
  }
  return baseHeight
}

function goToUrl(url: string) {
  window.open(url)
}

function openFeedback(type: string, id: string) {
  feedbackType.value = type
  feedbackId.value = id
  submitFeedback(false)
}

function submitFeedback(reset = true) {
  $fetch(`https://atl-search.lbm.co/api/chat/${feedbackId.value}/feedback/${feedbackType.value}`, {
    method: 'post',
    body: { comment: feedback.value },
  })
    .then(() => {
      if (feedbackId.value)
        feedbackComplete.value.push(feedbackId.value)

      if (reset) {
        feedbackType.value = null
        feedbackId.value = null
        feedback.value = ''
      }
    })
    .catch((error) => {
      console.error(error)
    })
}
</script>

<template>
  <div
    class="fixed bottom-0 right-0 md:mr-4 z-50 flex justify-end items-end"
  >
    <div
      class="relative w-screen md:w-[50vw] h-[630px] text-zinc-700 font-sans2 text-base origin-bottom-right transition-all duration-300 ease-in-out"
      :class="{
        'rounded bg-gluegray-200 border border-gluegray-300': open,
        'rounded-t-lg max-w-[100px] max-h-[40px] bg-red-500': !open,
        'max-w-[400px]': open,
      }"
    >
      <button
        aria-label="Open Chat"
        class="absolute inset-0 flex items-center justify-center space-x-1 font-sans2 uppercase font-bold text-sm transition-all duration-300 ease-in-out text-white"
        :class="{ 'opacity-0 pointer-events-none': open }"
        @click="open = true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
          class="w-6 h-6 text-white"
        >
          <path
            stroke-linecap="round" stroke-linejoin="round"
            d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
          />
        </svg>
        <span>Chat</span>
      </button>

      <div
        class="flex-1 h-full w-full origin-bottom-right"
        :class="{ 'absolute inset-0 opacity-0 pointer-events-none': !open }"
      >
        <div class="relative grid grid-cols-12 w-full" :class="{ 'flex-1': !showLinks }">
          <div
            class="relative text-gleublue-600 px-2 col-span-12 h-[600px]"
          >
            <div class="relative flex flex-none justify-center p-6 h-[85px]">
              <div class="flex flex-col items-center space-y-2 pointer-events-none text-glueblue-600">
                <div>
                  <LogosAtlantisLogo style="width: 120px; height: 20px" />
                </div>

                <div class="text-xs font-bold">
                  Ask Mona the Atlantis Virtual Assistant
                </div>

                <div style="margin-top:0;" class="relative text-xs text-zinc-600 pointer-events-auto">
                  Generative AI is experimental. Result quality may vary.
                  <button aria-label="Show Disclaimer" @click="showDisclaimer = !showDisclaimer">
                    <Icon name="heroicons:information-circle" class="w-4 h-4 text-zinc-600" />
                  </button>
                </div>
              </div>
              <button class="absolute top-0 right-0 bottom-0 flex items-center mr-3 text-glueblue-600" aria-label="Close Chat" @click="open = false">
                <svg
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-6 h-6"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <div
              class="mt-3 relative grid gap-1 pt-4 overflow-hidden bg-white border border-orange-300 border-b-0 rounded-t"
              :style="{ height: `${chatboxHeight}px` }"
            >
              <div id="chatbox" class="flex flex-col space-y-1 overflow-y-scroll pb-12">
                <div
                  v-for="(message, key) in messages" :key="key" class="flex" :class="{
                    'justify-end pl-12': message.type === 'user',
                    'justify-start pr-12': message.type === 'bot',
                  }"
                >
                  <div
                    class="p-3 text-sm rounded-lg mx-2" :class="{
                      'bg-gluegray-300 text-gray-800 rounded-tr-none': message.type === 'user',
                      'bg-gray-100 text-glueblue-600  rounded-tl-none': message.type === 'bot',
                    }"
                  >
                    <div
                      v-if="message.type === 'bot'" class="text-sm uppercase font-bold border-b "
                      :class="{ 'border-glueblue-600/20': message.type === 'bot', 'border-gray-800': message.type !== 'bot' }"
                    >
                      {{ message.type === 'bot' ? `MONA: Your Virtual Assistant` : 'You' }}
                    </div>
                    <div class="prose-a:font-bold prose-a:underline" v-html="message.text" />
                    <div v-if="message.type === 'bot' && key === messages.length - 1 && (key === 0 || links.length) && !messages[ messages.length - 1].text.includes('live agent')">
                      <div class="mt-4 text-bold uppercase text-glueblue-600 text-2xs font-bold">
                        Quick links
                      </div>
                      <ul class="mt-0.5 pt-2 border-t border-glueblue-600/20 flex flex-wrap">
                        <li
                          v-for="(link, lkey) in links" :key="lkey"
                          class="cursor-pointer group bg-white p-0.5 px-3 rounded-full border border-glueblue-600/50 text-glueblue-600 mr-1 mb-1"
                          @click="goToUrl(link.slug)"
                        >
                          <div class="text-xs font-medium line-clamp-3 duration-200">
                            {{ link.title }}
                          </div>
                        </li>
                        <li v-if="message.type === 'bot' && key === messages.length - 1 && key === 0" class="rounded-full px-2 py-1 text-white bg-glueblue-600 text-xs cursor-pointer mr-1 mb-1" @click="sendPremadeMessage('How do I make dining reservations?')">
                          Make Dining Reservations?
                        </li>
                        <li v-if="message.type === 'bot' && key === messages.length - 1 && key === 0" class="rounded-full px-2 py-1 text-white bg-glueblue-600 text-xs cursor-pointer mr-1 mb-1" @click="sendPremadeMessage('How do I buy a day pass?')">
                          Buy a day pass?
                        </li>
                        <li v-if="message.type === 'bot' && key === messages.length - 1 && key === 0" class="rounded-full px-2 py-1 text-white bg-glueblue-600 text-xs cursor-pointer mr-1 mb-1" @click="sendPremadeMessage('How do I book transportation to Atlantis from the airport?')">
                          Book Transportation?
                        </li>
                      </ul>
                    </div>

                    <div v-if="message.type === 'bot' && message.id && !feedbackComplete.includes(message.id.toString())" class="hidden lg:block">
                      <div class="mt-4 text-bold uppercase text-glueblue-600/50 text-2xs font-bold">
                        Feedback
                      </div>
                      <div
                        class="flex space-x-2 mt-0.5 pt-2 border-t border-glueblue-600/20 "
                      >
                        <button class="cursor-pointer group bg-white p-0.5 px-3 rounded-full border border-glueblue-600/50 text-glueblue-600 text-xs" @click="openFeedback('positive', message.id.toString())">
                          👍 Helpful
                        </button>
                        <button class="cursor-pointer group bg-white p-0.5 px-3 rounded-full border border-glueblue-600/50 text-glueblue-600 text-xs" @click="openFeedback('negative', message.id.toString())">
                          👎 Not Helpful
                        </button>
                      </div>
                    </div>
                    <button v-if="feedbackComplete.includes(message.id.toString())" class="text-2xs bg-blue-100 border border-blue-200 text-blue-600 p-0.5 px-2 rounded-full">
                      Thank you for your feedback!
                    </button>
                  </div>
                </div>

                <!-- Animate the bubble -->
                <Transition
                  mode="out-in" enter-active-class="ease-out" enter-from-class="opacity-0"
                  enter-to-class="opacity-100" leave-active-class="duration-200 ease-in" leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <div v-show="isSendingMessage" class="ml-2 flex justify-start pr-12 duration-300 transform-all">
                    <div class="flex items-end space-x-2 rounded-lg text-white p-3 text-xs bg-glueblue-600">
                      <span>Thinking</span>
                      <div id="helpbot-thinker" class="flex space-x-1 h-[12px] translate-y-[3px]">
                        <div class="w-[5px] h-[5px] bg-[#ccc] rounded-full" />
                        <div class="w-[5px] h-[5px] bg-[#ccc] rounded-full" />
                        <div class="w-[5px] h-[5px] bg-[#ccc] rounded-full" />
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
            <div>
              <div class="relative flex items-center  border border-orange-300 rounded-b bg-white">
                <label class="sr-only" for="new-message">New Message</label>
                <input
                  id="new-message"
                  v-model="newMessage" type="text"
                  class="w-full pb-2 pt-4 px-2 mr-8 mb-2 ml-2 focus:outline-none focus:border-b-2 border-glueblue-600   placeholder:text-zinc-500 text-glueblue-600 focus:outline-inner"
                  placeholder="New Message" @keyup.enter="sendMessage"
                >
                <button
                  aria-label="Send Message"
                  class="uppercase py-2 px-6 mr-2 rounded-br text-sm bg-red-500 text-white disabled:opacity-50 disabled:cursor-not-allowed"
                  :disabled="!canSendMessage"
                  @click="sendMessage"
                >
                  <div v-show="isSendingMessage" id="helpbot-thinker-post" class="flex space-x-1 h-6 translate-y-[6px]">
                    <div class="w-[5px] h-[5px] bg-[#fff] rounded-full" />
                    <div class="w-[5px] h-[5px] bg-[#fff] rounded-full" />
                    <div class="w-[5px] h-[5px] bg-[#fff] rounded-full" />
                  </div>
                  <span v-show="!isSendingMessage">Send</span>
                </button>
              </div>
              <div v-if="isOpenHours" class="flex justify-between p-3 bg-zinc-100 border-t border-orange-200">
                <div class="flex items-center space-x-1">
                  <button
                    class="flex items-center space-x-1 text-sm text-white bg-glueblue-600 py-2 px-4 rounded-sm"
                    aria-label="Switch to Live Agent"
                    @click="chatModeAi = false"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                      stroke="currentColor" class="w-[20px] h-[20px]"
                    >
                      <path
                        stroke-linecap="round" stroke-linejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    <span>Switch to Live Agent</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showDisclaimer" class="absolute inset-0 flex justify-center items-center bg-glueblue-700/50 ">
          <div class="bg-white p-8">
            <div class="text-glueblue-600 font-semibold text-lg tracking-tighter font-sans uppercase">
              Welcome to Ask Mona,
              The Atlantis virtual assistant!
            </div>

            <div class="mt-2 text-zinc-600 font-sans2 text-sm prose-sm">
              <p>
                We hope our helpbot, MONA, can assist you find what you're looking for at Atlantis. Do
                note that our bot uses Generative AI which is experimental, and results quality may
                vary. We will continue to monitor the responses that MONA returns to continue to
                improve its output.
              </p>

              <p>
                This chat is intended to assist consumers with their Atlantis questions. By using this
                chat, you agree that we may use and record your chat and its contents in accordance
                with our <a href="/privacy" class="font-bold underline">privacy policy</a> and <a
                  href="/terms"
                  class="font-bold underline"
                >terms of use</a>, and to help us continually improve its results.
              </p>
            </div>

            <div class="pt-8">
              <button class="glue-btn btn-2xs bg-glueblue-600 text-white" @click="acceptDisclaimer">
                I understand and
                agree to these terms.
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="feedbackType" class="absolute inset-0 p-12 bg-slate-200 flex flex-col">
      <div class="pb-2 text-2xl font-sans uppercase font-light">
        THANK YOU FOR YOUR FEEDBACK!
      </div>
      <div v-if="feedbackType === 'positive'" class="pb-4 text-base font-sans2 font-light">
        We are glad our helpbot’s response was useful. Any additional feedback will help us to continually improve the user experience.<br><br> Would you like to leave any additional feedback?
      </div>
      <div v-else class="pb-4 text-base font-sans2 font-light">
        Your insights and feedback will help us to continually improve the user experience and responses of our helpbot.<br><br> Would you like to leave any additional feedback?
      </div>
      <label class="sr-only" for="feedback">Feedback</label>
      <textarea id="feedback" v-model="feedback" class="w-full h-[200px] p-6 font-sans2 font-light rounded" />
      <div class="pt-4">
        <button :disabled="feedback === ''" class="font-sans2 uppercase py-2 px-6 mr-2 rounded text-sm bg-glueblue-600 text-white disabled:opacity-50 disabled:cursor-not-allowed" @click="submitFeedback(true)">
          Submit Feedback
        </button>
        <button class="font-sans2 uppercase py-2 px-6 mr-2 rounded text-sm text-glueblue-600 underline" @click="feedbackType = null">
          No thank you
        </button>
      </div>
    </div>
  </div>
</template>

<style>
.five9-frame {
  right: auto !important;
  left: 10% !important;
}
</style>
